import React from 'react';
import {graphql} from "gatsby";

import Layout from "../../components/layouts/Layout";
import SectionLayoutGrid from "../../components/layouts/SectionLayoutGrid";
import ContentLibraryCard from "../../components/cards/ContentLibraryCard";
import {convertCategories} from "../../components/general/content-library/ContentLibraryFunctions";

import Col from "../../components/styled/grid/Col";
import {Body, Header2} from "../../components/styled/typography/Typography";
import {ButtonContainer, ButtonLink} from "../../components/styled/button/ButtonComponents";
import {LottieAnimation} from "../../components/styled/lotti/LottieAnimations";

import circuit from "../../../static/assets/animations/circuit/circuit01.json";

const ContentLibraryThankYou = ({data, location}) => {

    const {locale} = data.content_library;
    const {downloads} = data.allStrapiContentLibraryDownloads;

    const {state = {}} = location;
    const {cldata} = state !== null ? state : [];

    const related1 = cldata ? downloads.find(f => f.slug === cldata.gated_content.related.slug1) : downloads[0]
    const related2 = cldata ? downloads.find(f => f.slug === cldata.gated_content.related.slug2) : downloads[1]
    const related3 = cldata ? downloads.find(f => f.slug === cldata.gated_content.related.slug3) : downloads[2]

    return (
        <Layout lang={locale} seo={{title: locale === "hu" ? "Köszönjük" : "Thank you", isIndexable: false}}>
            <LottieAnimation animationData={circuit} left="true"/>
            <SectionLayoutGrid customBackground="middle" customMinHeight="usecases" wide>
                <Col grid center marginBottom="large">
                    <Header2 color="white" textAlign="center" marginBottom="medium" upper marginTop="large">
                        {locale === "hu" ? "Köszönjük az érdeklődésed! " : "Thank you for your interest."}
                    </Header2>
                    <Body color="body-text" textAlign="center" marginBottom="small">
                        {locale === "hu" ? "A megadott email címre elküldtük a dokumentum letöltéséhez szükséges linket. " : "To download your document, we've sent a link to your email address."}
                    </Body>
                    <Col grid center>
                        <ButtonContainer>
                            <ButtonLink to={locale === "hu" ? "/hu/content-library/" : "/en/content-library/"}
                                        color="primary-brand">
                                {locale === "hu" ? "Vissza a dokumentumtárhoz" : "See all in Content Library"}
                            </ButtonLink>
                        </ButtonContainer>
                    </Col>
                </Col>
                <Col>
                    <Header2 color="white" textAlign="center" marginBottom="medium">
                        {locale === "hu" ? "Kapcsolódó dokumentumok" : "Related documents"}
                    </Header2>
                </Col>
                <Col span={4}>
                    <ContentLibraryCard
                        avatar={related1.avatar.url}
                        title={related1.title.length <= 40 ? related1.title : (related1.filters.lang === "ru" ? related1.title.slice(0, 33) + "..." : related1.title.slice(0, 40) + "...")}
                        category={convertCategories(locale, related1.filters.category)}
                        isSmall
                        slug={related1.gated_content ? "/" + related1.filters.lang + "/content-library/" + related1.slug : undefined}
                        ss_link={related1.ss_tracking_link}
                        gated={!!related1.gated_content}
                    />
                </Col>
                <Col span={4}>
                    <ContentLibraryCard
                        avatar={related2.avatar.url}
                        title={related2.title.length <= 40 ? related2.title : (related2.filters.lang === "ru" ? related2.title.slice(0, 33) + "..." : related2.title.slice(0, 40) + "...")}
                        category={convertCategories(locale, related2.filters.category)}
                        isSmall
                        slug={related2.gated_content ? "/" + related2.filters.lang + "/content-library/" + related2.slug : undefined}
                        ss_link={related2.ss_tracking_link}
                        gated={!!related2.gated_content}
                    />
                </Col>
                <Col span={4}>
                    <ContentLibraryCard
                        avatar={related3.avatar.url}
                        title={related3.title.length <= 40 ? related3.title : (related3.filters.lang === "ru" ? related3.title.slice(0, 33) + "..." : related3.title.slice(0, 40) + "...")}
                        category={convertCategories(locale, related3.filters.category)}
                        isSmall
                        slug={related3.gated_content ? "/" + related3.filters.lang + "/content-library/" + related3.slug : undefined}
                        ss_link={related3.ss_tracking_link}
                        gated={!!related3.gated_content}
                    />
                </Col>
            </SectionLayoutGrid>
        </Layout>
    );
};

export const query = graphql`
   query GetSingleContentLibraryThankYou($locale: String) {
        content_library: strapiContentLibrary(locale: { eq: $locale }) {
          locale
        }
        allStrapiContentLibraryDownloads {
            downloads: nodes {
              products {
                name
                slug
              }
              filters {
                category
                lang
                theme
              }
              title
              ss_tracking_link
              date
              slug
              gated_content {
                list_token
              }
              avatar {
                  url
              }
            }
         }
   }
`

export default ContentLibraryThankYou;